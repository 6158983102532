import 'typeahead.js';
import Bloodhound from 'typeahead.js/dist/bloodhound';

export default {
    vacancies: new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        // prefetch: '/technical/typeahead-vacancies',
        remote: {
            url: `/technical/typeahead-vacancies?query=%QUERY`,
            wildcard: '%QUERY'
        }
    }),

    locations: new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('city postcode'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: '/technical/list-locations?prefetch=uk',
        remote: {
            url: `/technical/typeahead-locations?query=%QUERY`,
            wildcard: '%QUERY'
        }
    }),

    compile: (data) => data.hasOwnProperty('country')
        ? `<div>${data.city}, ${data.state}, ${data.country} ${data.postcode}</div>`
        : `<div>${data.city}, ${data.county}, ${data.state} ${data.postcode}</div>`,

    init() {
        $('.search-input[name="job-title"]').typeahead(
            {
                minLength: 1,
                highlight: true
            },
            {
                name: 'job-title',
                display: 'title',
                limit: 10,
                source: this.vacancies
            }
        );

        $('.search-input[name="location"]').typeahead(
            {
                minLength: 2,
                highlight: true,
            },
            {
                name: 'locations',
                display: 'city',
                limit: 15,
                source: this.locations,
                templates: {suggestion: this.compile,}
            }
        )
            .bind('typeahead:select', function(ev, suggestion) {
                const locationId = suggestion.hasOwnProperty('country')
                    ? suggestion.id
                    : 'uk-' + suggestion.id;

                $(ev.target).parents('form').find('input[name="location-id"]').val(locationId);
            });
    }
}
